.form-relatorio-box-row {
    margin-left: 0px;
    margin-right: 0px;
}

.form-relatorio-box {
    padding-top: 7px;
    padding-bottom: 7px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.form-relatorio-box-bg {
    background: $azul-mais-claro;
    border: 1px solid $cinza-inputs;
    @include border-radius(3px);
}
.form-report {
    .form-filter {
        hr {
            margin-bottom: 5px;
            margin-top: 5px;
        }
    }
}
.form-report-filter-hr {
    margin-bottom: 5px;
    margin-top: 5px;
}
.form-inline {
    .padding-top {
        padding-top: 3px;
    }
}