/*escolas*/

  .select-alt{
    width:44% !important;
  }

  .escola-pophover{
    display: inline-table;
    vertical-align: middle;
    margin-left: 3px;
  }

  .bg-pophover{
    margin-top: 5px;
  }

  .check-tabelas-escolas{
    font-size:0.45em;
  }
  .check-icons-th{
    i{
  padding-top: 10px;
  }
}

  .tabela-escola{
    margin-bottom: 10px;
  }
  .tabela-escola thead th:first-child{
    width: 9%;
  }

  .tabela-escola tbody td:nth-child(3) {
  }

  .check-etapas{
    text-align: center;
  }

  .tabela-escola{
    overflow-x: scroll;
  }
  // .tabela-escola tbody td:nth-child(3) .radio-inline + .radio-inline, .tabela-views tbody td:nth-child(2) .checkbox-inline + .checkbox-inline {
  //   margin-left: 0px; }
