@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  transition: $args;
}

@mixin button($color,$color2) {
 background:$color;
 border-bottom:2px solid $color2;
 color:$branco;
 font-size: 2.0em;
 padding: 4px 8px;
 font-weight: 500;

}

@mixin background-opacity($color, $opacity) {
    background: $color; /* The Fallback */
    background: rgba($color, $opacity);
}

@mixin linear-gradient($color-1, $color-2, $color-3) {
    background:-webkit-linear-gradient($color-1, $color-2, $color-3);
    background:   -moz-linear-gradient($color-1, $color-2, $color-3);
    background:    -ms-linear-gradient($color-1, $color-2, $color-3);
    background:     -o-linear-gradient($color-1, $color-2, $color-3);
    background:        linear-gradient($color-1, $color-2, $color-3);
}

@mixin prefix($property, $value, $vendors: webkit moz ms o) {
      @if $vendors {
        @each $vendor in $vendors {
          #{"-" + $vendor + "-" + $property}: #{$value};
        }
      }
      #{$property}: #{$value};
}

@mixin prefix($property, $value, $vendors: webkit moz ms o) {
      @if $vendors {
        @each $vendor in $vendors {
          #{"-" + $vendor + "-" + $property}: #{$value};
        }
      }
      #{$property}: #{$value};
}
