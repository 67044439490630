.bg {
  @include border-radius(3px);
  padding: 5px 5px;
  font-size: 2.0em;
  i {
      font-size: 2.0em;
      vertical-align: middle;
      color:$branco;
      margin-right: 20px;
  }

  article {
    font-size: 1.0em;
    margin-left: 50px;
    font-weight: 700;
  }

  button{
    font-size: 1.0em;
    margin-right: 10px;
    width:75px;
    margin-top: 10px;
  }

  .btn-alerta {
    vertical-align: middle;
  }
}

.bg-alerta {
  span{
    vertical-align: middle;
  }
}

.bg-add{
   button{
     margin-top: 0px;
     width: auto;
   }
}

.bg-avisos{
  margin: 0 auto;
  display: inline-block;
  width: 360px;
  height: 35px;
  margin-right: 25px;
  text-align: left;
  right: 300px;
  position: absolute;
}

.bg-avisos-alertas {
  background: $amarelo-toolhint;
}

.bg-avisos-errors {
  background: $vermelho-claro;
  color: $branco;
}

.bg-avisos-errors-large {
  width: 410px !important;
}

.alert{
  span {
    vertical-align: middle;
  }
}

.bg-menores{

  i{
    font-size: 2.0em;
    margin-right: 10px;
  }
}

.bg-avisos-filtro{
  position: relative;
  width: 300px !important;
  right: 0px;
}

// .bg-avisos-errors:after{
//   content: ' ';
//   width: 0;
//   height: 0;
//   border-style: solid;
//   border-width: 10px 0 10px 15px;
//   border-color: transparent transparent transparent $vermelho-claro;
//   position: absolute;
//   top: 7px;
//   left: 355px;
// }
//
// .bg-avisos-alertas:after{
//   content: ' ';
//   width: 0;
//   height: 0;
//   border-style: solid;
//   border-width: 10px 0 10px 15px;
//   border-color: transparent transparent transparent $amarelo-toolhint;
//   position: absolute;
//   top: 7px;
//   left: 355px;
// }


.btn-pophover {
 color: $azul-claro;
 font-size: 3.3em;
 @include transition(.4s);
 &:hover {
   opacity: 0.7;
 }

}


.red-tooltip + .tooltip > .tooltip-inner {
  background-color: $cor-pop-hover-1;
  color: $preto-texto-padrao;
}
.tooltip.top .tooltip-arrow {
    border-top-color: $cor-pop-hover-1;
}

.tooltip.right .tooltip-arrow {
    border-right-color: $cor-pop-hover-1;
}

.tooltip.bottom .tooltip-arrow {
    border-bottom-color: $cor-pop-hover-1;
}

.tooltip.left .tooltip-arrow {
    border-left-color: $cor-pop-hover-1;
}


.bg-sucesso {
  background:$verde-claro;
  color: $branco;
}

.bg-error {
  background:$vermelho-claro;
  color: $branco;
}

.bg-error-coleta {
  background:$vermelho-padrao;
  color: $branco;
}

.bg-alerta {
  background: $amarelo-claro;
  color:$branco;
  padding: 10px;
  i{
    color:$branco;
  }
}


.bg-toolhint {
  min-height: 35px;
  i {
    margin-right: 5px;
    font-size: 1.6em;
    /*line-height: 0.8em;*/
    float: right;
  }
  &::before {

  }
  p{
    font-size: 1.3em;
    line-height: 1.2em;
    span {
      line-height: 1.6em;
    }
  }
  b {
    font-weight: bolder;
  }
}

.bg-toolhint:before {
  border: none;
}


.bg-toolhint-sucesso:before{
  content: ' ';
  width: 0;
  height: 0;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-right: 9px solid $verde-toolhint;
  position: absolute;
  top: 7px;
  left: 7px;
}

.bg-toolhint-alerta:before{
  content: ' ';
  width: 0;
  height: 0;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-right: 9px solid $amarelo-toolhint;
  position: absolute;
  top: 7px;
  left: 7px;
}

.bg-toolhint-error:before{
  content: ' ';
  width: 0;
  height: 0;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-right: 9px solid $vermelho-claro;
  position: absolute;
  top: 7px;
  left: 7px;
}


.bg-toolhint-sucesso{
  background:$verde-toolhint;
}
.bg-toolhint-alerta{
  background:$amarelo-toolhint;
}

.bg-toolhint-error {
  background: $vermelho-claro;
  color:$branco;
  // i {
  //   font-size: 3.0em;
  // }
}

.btn-padrao{
  @include border-radius(3px);
  @include transition(.4s);
  &:focus{
    color:$branco;
  }
}

.btn-azul-padrao {
  @include button($azul-padrao,$azul-escuro);
  &:hover {
    background:$azul-claro;
    color:$branco;
  }
  &:active {
    background:$azul-claro;
  }
}

.btn-azul-claro {
  @include button($azul-claro,$azul-padrao);
  &:hover {
    opacity: 0.7;
    color:$branco;
  }  &:active {
      background:$azul-claro;
    }
}

.btn-verde-padrao {
  @include button($verde-padrao,$verde-escuro);
  &:hover {
    background:$verde-claro;
    color:$branco;
  }
  &:active {
    background:$verde-claro;
  }
}

.btn-verde-claro {
  @include button($verde-claro,$verde-padrao);
  &:hover {
    opacity: 0.7;
    color:$branco;
  }
}

.btn-vermelho-padrao {
  @include button($vermelho-padrao,$vermelho-escuro);
  &:hover {
    background:$vermelho-claro;
    color:$branco;
  }
}

.btn-vermelho-claro {
  @include button($vermelho-claro,$vermelho-padrao);
  &:hover {
    opacity: 0.7;
    color:$branco;
  }
}

.btn-amarelo-padrao {
  @include button($amarelo-padrao,$amarelo-escuro);
  &:hover {
    background:$amarelo-claro;
    color:$branco;
  }
}

.btn-amarelo-claro {
  @include button($amarelo-claro,$amarelo-padrao);
  &:hover {
    opacity: 0.7;
    color:$branco;
  }  &:active {
      background:$amarelo-claro;
    }
}

.btn-cinza-padrao {
  @include button($cinza-pesquisa,$cinza-escuro);
  &:hover {
    opacity: 0.7;
    color:$branco;
  }
  text-transform: uppercase;
  color: #686868;
}


.btn-error-form{
      background: $vermelho-claro;
      color:$branco;
      padding: 5px;
      width: 30px;
      height: 30px;
      @include border-radius(100%);
}

.btn-ok-form{
      background:$verde-claro;
      color:$branco;
      padding: 5px;
      width: 30px;
      height: 30px;
      @include border-radius(100%);
}

.bg-error-item-form{
  background: #f2dede;
  font-size:inherit;
  padding:0px !important;
  margin:0px !important;
  margin-top: 10px !important;
}


/***CSS-RESET***/

.bg-menores {
  i {
    float: left;
    margin-left: 10px;
  }
  span {
    float: left;
    padding-top: 5px;
  }
}


.btn-azul-escuro {
  @include button($azul-padrao,$azul-escuro);
  &:hover {
    opacity: 0.7;
    color:$branco;
  }  &:active {
      background:$azul-padrao;
    }
}


/***bootstrap sobrescrita***/
.alert-success {
  color: $branco;
  background-color: $verde-padrao;
  border-color:none;
}

.alert-success {
  background-image: -webkit-linear-gradient(top, $verde-padrao 0%, $verde-padrao 100%);
  background-image:      -o-linear-gradient(top, $verde-padrao 0%, $verde-padrao 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from($verde-padrao), to($verde-padrao));
  background-image:         linear-gradient(to bottom, $verde-padrao 0%, $verde-padrao 100%);
  background-repeat: repeat-x;
  border-color: $verde-padrao;
  color: $branco;
  border-color:none;
}

.alert-success {
  color: $branco;
  background-color: $verde-padrao;
  border-color:none;
}

.alert-success {
  background-image: -webkit-linear-gradient(top, $verde-padrao 0%, $verde-padrao 100%);
  background-image:      -o-linear-gradient(top, $verde-padrao 0%, $verde-padrao 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from($verde-padrao), to($verde-padrao));
  background-image:         linear-gradient(to bottom, $verde-padrao 0%, $verde-padrao 100%);
  background-repeat: repeat-x;
  border-color: $verde-padrao;
  color: $branco;
  border-color:none;
}

.alert-danger {
  color: $branco;
  background-color:$vermelho-claro;
  border-color: none;
}

.alert-danger {
  background-image: -webkit-linear-gradient(top, $vermelho-claro 0%, $vermelho-claro 100%);
  background-image:      -o-linear-gradient(top, $vermelho-claro 0%, $vermelho-claro 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from($vermelho-claro), to($vermelho-claro));
  background-image:         linear-gradient(to bottom, $vermelho-claro 0%, $vermelho-claro 100%);
  background-repeat: repeat-x;
  border-color: none;
  color: $branco;
  border-color:none;
}

.btn-primary {
  color: #fff;
  background-color: $azul-padrao;
  border-color: $azul-padrao;
  border-bottom:2px solid $azul-escuro;
}
.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: $azul-claro;
  border-color: $azul-claro;
}
.btn-primary:hover {
  color: #fff;
  background-color: $azul-claro;
  border-color: 2px solid $azul-padrao;
}
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: $azul-claro;
  border-color: $azul-padrao;
}


.btn-success {
  color: #fff;
  background-color: $verde-padrao;
  border-color: $verde-padrao;
  border-bottom: 2px solid $verde-escuro;
}
.btn-success:focus,
.btn-success.focus {
  color: #fff;
  background-color: $verde-claro;
  border-color: $verde-claro;
  border-bottom: 2px solid $verde-padrao;
}
.btn-success:hover {
  color: #fff;
  background-color: $verde-claro;
  border-color: $verde-claro;
  border-bottom: 2px solid $verde-padrao;
}
.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
  color: #fff;
  background-color: $verde-claro;
  border-color: $verde-claro;
  border-bottom: 2px solid $verde-padrao;
}

/** barra botoes pesquisa***/
.bar-btn-pesquisa{
  float: right;
  button {

  }
}

/*botão fixo resp-coleta*/

.btn-fixed{
  position: fixed;
  right: 0px;
  top: 100px;
  z-index: 5000;
}
