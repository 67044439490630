.form-usuario-profile-box-row {
    margin-left: 0px;
    margin-right: 0px;
}

.form-usuario-profile-box {
    padding-top: 7px;
    padding-bottom: 7px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.form-usuario-profile-box-bg {
    background: $azul-mais-claro;
    border: 1px solid $cinza-inputs;
    @include border-radius(3px);
}