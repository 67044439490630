/***topo***/

.topo-fixo {
    background: $azul-padrao;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 2.0em;
    max-width: 100%;
    .wrap {}
    .user {
        color: $branco;
        text-decoration: underline;
        i {
            background: $branco;
            @include border-radius (100%);
            padding: 5px;
            color: $azul-padrao;
        }
    }
    .sair {
        color: $branco;
        a {
            color: $branco;
            vertical-align: middle;
            text-decoration: none;
        }
        i {
            background: $vermelho-padrao;
            padding: 3px;
            @include border-radius (4px);
            @include transition(.4);
            font-size: 0.95em;
            margin-right: 5px;
            margin-top: -10px;
            &:hover {
                background: $vermelho-claro;
            }
        }
    }
}

.topo {
    .titulo {
        position: relative;
    }
    h3 {
        font-size: 4.2em;
        font-weight: bolder;
        color: $azul-padrao;
        margin-top: 80px;
        text-align: right;
    }
    .sistema h3 {
        font-size: 4.2em;
        text-align: right;
        margin-top: 80px;
        font-weight: bolder;
        color: $azul-padrao;
    }
}


/***topo***/


/* navegacao */

#nav-ias {
    background: none;
    border: none;
    margin-top: 20px;
    margin-bottom: 50px;
    box-shadow: none;
}

#nav-ias ul {
    padding: 0;
    width: 100%;
    height: 40px;
}

#nav-ias li {
    display: inline;
    float: left;
}

#nav-ias a {
    display: inline-block;
    text-decoration: none;
}

#nav-ias li a {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

#nav-ias li:last-child a {
    border-right: 0;
}

#nav-ias a#pull {
    display: none;
}

.menu-menu-principal-container {}

ul,
ol,
dd {
    text-decoration: none;
}

a {
    text-decoration: none;
    color: $preto-texto-padrao;
    cursor: pointer;
}

.nav {
    list-style: none;
    margin-left: 0;
    padding: 0;
}

.nav>li,
.nav>li>a {
    display: inline-block;
    *display: inline;
    zoom: 1;
}

.header-nav {
    display: block;
    margin-right: 1.78571%;
    width: 100%;
    font-size: 2.8em;
}

.main-menu {
    display: inline-block;
    position: relative;
    margin-right: 0px;
}

.header-nav .main-menu li {
    margin-left: 25px;
}

.header-nav .main-menu li:first-child {
    margin-left: 0px;
    padding-left: 0px;
}

.menu-item {
    padding: 10px;
}

.header-nav,
.main-menu {
    height: 40px;
}

.header-nav .main-menu li ul {
    display: none;
}

.header-nav .main-menu li:hover ul {
    display: block;
}

.menu-item>ul {
    position: absolute;
    background-color: $azul-padrao;
    padding: 10px;
    border-radius: 4px;
    /*box-shadow: 0px 0px 5px 0px rgba(160,160,160,0.75);*/
    -webkit-box-shadow: 0px 4px 10px -4px rgba(50, 50, 50, 0.55);
    -moz-box-shadow: 0px 4px 10px -4px rgba(50, 50, 50, 0.55);
    box-shadow: 0px 4px 10px -4px rgba(50, 50, 50, 0.55);
    transform: translateY(10px);
    z-index: 99998;
    left: 0px;
}

.menu-item>ul:before {
    content: ' ';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid $azul-padrao;
    position: absolute;
    top: -9px;
    z-index: 99999;
}

.item-1>ul:before {
    left: 20px;
}

.item-2>ul:before {
    left: 130px;
}

.item-3>ul:before {
    left: 245px;
}

.item-4>ul:before {
    left: 360px;
}

.item-5>ul:before {
    left: 460px;
}

.item-6>ul:before {
    left: 578px;
}

.item-7>ul:before {
    left: 695px;
}

.menu-item>ul li {
    margin: 0;
    margin-left: 20px;
}

.menu-item>ul li a {
    color: $branco;
    font-weight: lighter;
    font-size: 16px;
    padding: 7px 0px 0px 7px;
}

.menu-item>ul li a:hover {
    color: $amarelo-padrao;
    background: none;
}

.menu-item>ul li a:focus,
.menu-item>ul li a:active,
.menu-item>ul li a:visited {
    background: none;
}

.nav-inline {
    list-style: none;
}

.nav-inline li {
    display: inline-block;
}

.menu-menu-principal-container a {
    color: $azul-padrao;
    text-decoration: none;
}

.menu-menu-principal-container a:hover {
    color: #afafaf;
    text-decoration: none;
}

.dropdown-menu {
    top: 70%;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.dropdown-menu>li>a:hover {
    color: #ffd847 !important;
}

.multiselect .dropdown-menu {
    top: 100%;
    margin-bottom: 55px !important;
}