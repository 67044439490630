
/***CSS-RESET***/

@font-face {
  font-family: 'fontawesome';
  src: url(../fonts/FONTAWESOME_0.OTF);
  font-weight: normal;
  font-style: normal;
}

.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}
.clearfix:after {
    clear: both;
}
.clearfix {
    *zoom: 1;
}



* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}


body {
  background: $branco;
  font-size: 62.5%;
  font-family: $font-default;
  color: $preto-texto-padrao;
  overflow-x: hidden;
  overflow-y: visible;
  -ms-overflow-style: scrollbar;
}
a{
  text-decoration:none;
}

input,
select,
label,
textarea {
  @include border-radius(3px);
  font-size: 2.2em;
  color: $cinza-labels;
  border-color:$cinza-inputs;
  font-weight: normal;

}


label b {
  color: $vermelho-claro;
}

.has-error .form-control{
  border-color: $vermelho-padrao;
}

.form-obrigatorio{
  font-size: 2.0em;
  color: $vermelho-padrao;
}

form button {
  height: 34px;
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    $dusty-gray;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color:    $dusty-gray;
   opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
   color:    $dusty-gray;
   opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color:    $dusty-gray;
}

//
// .radio {
//   margin-bottom: 20px;
// }
//
// .radio label {
//   display: inline-block;
//   cursor: pointer;
//   position: relative;
//   padding-left: 20px;
//   margin-right: 20px;
//   font-size: 14px;
// }
// input[type=radio] {
//   display: none;
// }
// input[type=checkbox] {
// display: none;
// }
//
// .radio label:before {
//   content: "";
//   display: inline-block;
//
//   width: 16px;
//   height: 16px;
//
//   margin-right: 10px;
//   position: absolute;
//   left: 0;
//   bottom: 1px;
//   background-color: none;
//   border:1px solid $azul-padrao;
//   box-shadow: inset 0px 2px 3px 0px rgba(0, 0, 0, .3), 0px 1px 0px 0px rgba(255, 255, 255, .8);
// }
//
// .radio label:before {
//   border-radius: 8px;
// }
//
// input[type=radio]:checked + label:before {
//     content: "\2022";
//     color: $azul-padrao;
//     font-size: 30px;
//     text-align: center;
//     line-height: 10px;
// }
//
// .checkbox label:before {
//     border-radius: 3px;
//     background: $branco;
// }
// input[type=checkbox]:checked + label:before {
//   content: "\2713";
//   text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
//   font-size: 15px;
//   font-weight: bolder;
//   color: $azul-padrao;
//   text-align: center;
//   line-height: 15px;
// }

input[type="text"]:disabled{
  background: $azul-mais-claro;
}

textarea:disabled {
  background: $azul-mais-claro;
}

.paginacao-paginas {
  font-size: 2.0em;
}

.pagination > li > a, .pagination > li > span {
    margin-left:1px;
    line-height: 1.42857143;
    color:$branco;
    text-decoration: none;
    background-color:$cinza-pagination;
    border-bottom: 1px solid $cinza-borda-pagination;
    @include transition(.4);
    @include border-radius(3px);
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  z-index: 2;
  color:$branco;
  background-color: $azul-padrao;
  border-bottom: 1px solid $azul-escuro;
  @include transition(.4);
  @include border-radius(3px);
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  z-index: 3;
  color: $branco;
  cursor: default;
  background-color: $azul-escuro;
}
.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  color: #777;
  cursor: not-allowed;
  background-color: $cinza-claro;
  border-bottom: 1px solid $cinza-pagination;
}

.mt10 {
  margin-top: 10px;
}

.table-striped > tbody > tr:nth-of-type(odd) {
    background-color: #effaff;
}

textarea {
  padding: 5px;
  border: 1px solid #ccc;
}

h6 {
  font-size: 2.2em;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background: $azul-mais-claro;
  opacity: 1;
}

.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
  cursor: not-allowed;
filter: alpha(opacity=65);
-webkit-box-shadow: none;
box-shadow: none;
opacity: .65;
}

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
 {
  color: $vermelho-padrao;
  font-size:2.0em;
}

.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline, {
  color:#666666 !important;
}


 .panel-solicitacao{
   text-decoration: none;
   a{
     text-decoration: none;
   }
 }

 .panel-heading{
   padding: 5px 15px;
 }

 .panel-primary > .panel-heading {
   background-image: -webkit-linear-gradient(top, $azul-padrao 0%, $azul-escuro 100%);
   background-image:      -o-linear-gradient(top, $azul-padrao 0%, $azul-escuro 100%);
   background-image: -webkit-gradient(linear, left top, left bottom, from($azul-padrao), to($azul-escuro));
   background-image:         linear-gradient(to bottom, $azul-padrao 0%, $azul-escuro 100%);
   background-repeat: repeat-x;
 }

 .panel-primary > .panel-heading + .panel-collapse > .panel-body {
     border-top-color: #ddd;
 }


 .panel-success > .panel-heading {
   color: $branco;
   background-color: $verde-padrao;
   border-color: #ddd;
 }

 .panel-success > .panel-heading {
   background-image: -webkit-linear-gradient(top, $verde-padrao 0%, $verde-escuro 100%);
   background-image:      -o-linear-gradient(top, $verde-padrao 0%, $verde-escuro 100%);
   background-image: -webkit-gradient(linear, left top, left bottom, from($verde-padrao), to($verde-escuro));
   background-image:         linear-gradient(to bottom, $verde-padrao 0%, $verde-escuro 100%);
   background-repeat: repeat-x;
 }

 .panel-warning > .panel-heading {
     color: $branco;
     background-color: $amarelo-padrao;
     border-color: #ddd;
 }

 .panel-warning > .panel-heading {
   background-image: -webkit-linear-gradient(top, $amarelo-claro 0%, $amarelo-padrao 100%);
   background-image:      -o-linear-gradient(top, $amarelo-claro 0%, $amarelo-padrao 100%);
   background-image: -webkit-gradient(linear, left top, left bottom, from($amarelo-claro), to($amarelo-padrao));
   background-image:         linear-gradient(to bottom, $amarelo-claro 0%, $amarelo-padrao 100%);
   background-repeat: repeat-x;
 }

 .panel-danger > .panel-heading {
     color: $branco;
     background-color: $vermelho-padrao;
     border-color: #ddd;
 }

 .panel-danger > .panel-heading {
   background-image: -webkit-linear-gradient(top, $vermelho-claro 0%, $vermelho-padrao 100%);
   background-image:      -o-linear-gradient(top, $vermelho-claro 0%, $vermelho-padrao 100%);
   background-image: -webkit-gradient(linear, left top, left bottom, from($vermelho-claro), to($vermelho-padrao));
   background-image:         linear-gradient(to bottom, $vermelho-claro 0%, $vermelho-padrao 100%);
   background-repeat: repeat-x;
 }

 .panel-heading a:before {
     content: '\e114';
     position: relative;
     font-family: 'Glyphicons Halflings';
     font-size: 18px;
     transition: all 0.5s;
     transform: scale(1);
     float: right;
     display: none;
 }

 .alert-success {
   color: $branco;
   background-color: $verde-padrao;
   border-color:none;
 }

 .alert-success {
   background-image: -webkit-linear-gradient(top, $verde-padrao 0%, $verde-padrao 100%);
   background-image:      -o-linear-gradient(top, $verde-padrao 0%, $verde-padrao 100%);
   background-image: -webkit-gradient(linear, left top, left bottom, from($verde-padrao), to($verde-padrao));
   background-image:         linear-gradient(to bottom, $verde-padrao 0%, $verde-padrao 100%);
   background-repeat: repeat-x;
   border-color: $verde-padrao;
   color: $branco;
   border-color:none;
 }

 .alert-warning {
   background-image: -webkit-linear-gradient(top, $amarelo-claro 0%, $amarelo-claro 100%);
   background-image:      -o-linear-gradient(top, $amarelo-claro 0%, $amarelo-claro 100%);
   background-image: -webkit-gradient(linear, left top, left bottom, from($amarelo-claro), to($amarelo-claro));
   background-image:         linear-gradient(to bottom, $amarelo-claro 0%, $amarelo-claro 100%);
   background-repeat: repeat-x;
   border-color: $amarelo-claro;
   color: $preto-texto-padrao;

   border-color:none;
 }

 .dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus{
   background: $azul-padrao !important;
 }

 .multiselect .dropdown-menu > li > a.item-selected:hover{
   background: $azul-claro !important;
 }
