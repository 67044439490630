@media only screen and (max-width: 1200px) {

    .group-pesquisar > #input-pesq, .form-pesquisar #input-pesq {
      min-width:400px !important;
    }

    .bg-avisos-alertas:after, .bg-avisos-errors:after{
      left: 355px;
    }

    .multiselect .btn{
      min-width:400px !important;
    }

    .reset-password-error {
      padding: 10px 0;
    }
}

@media only screen and (max-width: 1024px) {
    #nav-ias ul {
      width: 100%;
    }

    .group-pesquisar > #input-pesq, .form-pesquisar #input-pesq {
      min-width:400px !important;
    }

    .group-pesquisar button.multiselect{
      min-width:400px !important;
    }

    // .bg-form-medidas{
    //   width:48%;
    // }

    .form-line-turma{
      width: auto;
    }

}

@media only screen and (max-width: 991px) {
  .login {
    .box-content-login {
        text-align: center;
        width: 100%;
        padding-bottom: 32px;
    }
      .aside-login {
        float: none;
    }
  }

  .group-pesquisar > #input-pesq {
    min-width: 300px !important;
  }
  
  .wrapper, .modal-content {
    .box-right-tabela {
      .bg-avisos-errors, .bg-avisos-alertas {
          right: 165px;
      }
    }
   }
   .wrapper {     
    .bg-avisos{
          width: 58%;
          right: 29%
     }
   }
   .wrapper-programas {
     .bg-avisos{
          width: 47%;
          right: 40%;
     }
   }
   .wrapper-edit {
     .bg-avisos{
          right: 52%;
          width: 52%;
     }
   }
   
  .bg-avisos{
    width: 60%;
  }

  .bg-avisos-alertas:after, .bg-avisos-errors:after{
    left: 345px;
  }

  .pesq-select{
    width: 300px !important;
  }

  .group-pesquisar > #input-pesq, .form-pesquisar #input-pesq {
    min-width:300px !important;
  }

  .group-pesquisar button.multiselect{
    min-width:300px !important;
  }

  .bg-pesquisas {
    width:100%;
  }

  .check-tabelas {
    i{
      font-size: 1.4em;
    }
  }

  .box-rec-senha{
    width: 80%;
  }

  .tabelas {
    // overflow-x: scroll;
  }

  .tabela-views{
    tbody td:nth-child(3){
      width: 28%;
    }
    tbody td:nth-child(2){
      text-align: center;
      width: 55%;
    }
  }

  .ajuste-input{
    padding-left: 15px;
    padding-right: 15px;
  }

  .ordem-modal{
    input{
      margin-bottom: 15px;
    }
  }

  .check-icon-tabelas{
    padding-left:5px;
  }

  .select-alt{
    width:60% !important;
  }
  .select-alt-cont{
    width:95% !important;
  }

  .select-cont-esc{
    width: 89% !important;
  }

  .bg-form-medidas{
    width:96.5%;
    margin-left: 15px;
    margin-right: 15px;
  }

  .group-pesquisar > #input-pesq, .form-pesquisar #input-pesq {
    min-width:350px;
  }

  .multiselect .btn{
    min-width:300px !important;
  }

  .medida-linha{
    margin-right: -15px;
  }

}

@media only screen and (min-width: 768px) and (max-width: 990px)  {
  .tabelas {
    overflow-x: scroll;
  }
  .tabela-resposta, .tabela-escola {
    overflow-x: scroll;
  }

  .reset-password-error {
    padding: 10px 12px;
  }

  .box-senha {
    margin-left: 12px;
    margin-right: 12px;
  }
}

@media only screen and (max-width: 767px) {

  .wrap{
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .topo-fixo{
    .wrap{
      padding-bottom: 5px;
    }
  }
  .bg-form-medidas{
    margin-left: 15px;
    margin-right: 15px;
  }

  .navbar-default{
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }


  .navbar-header {
    background: $cinza-nav-bar;
  }

  .navbar-default .navbar-toggle .icon-bar{
  background: $azul-padrao;
  }
  .navbar-toggle{
    border: none;
  }

  #nav-ias{
    width: 95%;
    height: auto;
    margin: 0 auto;
    margin-bottom:10px;
 }

  .wrap {
    margin-left: 7px;
    margin-right: 7px;
  }

  #nav-ias ul {
    margin-left: 0px;
  }

  .pesquisa-fixa {
    float: none;
  }

  .topo {
    .sistema h3{
      text-align: center;
      margin-top: 0px;
      font-size: 5.8em;
    }
    margin-bottom: 35px;
    .logo-ias{
      text-align: center;
    }
  }


    .header-nav {
      font-size: 2.6em;
    }

    .menu-item > ul:before {
      display: none;
    }

  .login {
    .box-content-login {
        text-align: center;
        width: 100%;
    }
      .aside-login {
        float: none;
    }
  }


  .group-pesquisar > #input-pesq, .form-pesquisar #input-pesq  {
    min-width: 75% !important;
  }

  .pesq-select{
    min-width: 100% !important;
  }

  .select-pesquisar{
    min-width: 75%;
  }
  .group-pesquisar button.multiselect{
    min-width: 100% !important;
  }

  .barra-pesquisa {
    margin-left: 0px;
    margin-right: 0px;
  }

  .btn-tabela-form {
    min-width: 100%;
    margin: 0 auto;
    height: 35px;
  }

  .box-left-tabela {
    text-align: center;
  }
  .box-right-tabela{
    text-align: center;
  }

  .box-bts-tabelas {
    text-align: center;
    button {
      margin-top: 5px;
    }
  }

  .bg-avisos{
    margin-top: 20px;
    float: none;
    width: 100%;
    position: relative;
    right:0px;
    text-align: center;
    left: 0px;
  }

  .bg-avisos-alertas:after{
    display: none;
  }

  .bg-avisos-errors:after{
    display: none;
  }

  .bg-toolhint {
    margin-top: 15px;
  }

  .bg-toolhint-alerta:before{
    display: none;
  }
  .bg-toolhint-sucesso:before {
    display: none;
  }

    p {
      margin-bottom: 0;
    }
  	.header-nav, .main-menu {
  		height: auto;
  	}

  	.header-nav .main-menu li {
  		margin-left: 0px;
  	}

    	#nav-ias ul {
   		  width: 100%;
    		height: auto;
    	}
    	#nav-ias li {
    		width: 97%;
    		float: left;
    		position: relative;
    	}
    	#nav-ias li a {
      padding:5px;
  	}
    	#nav-ias a {
  	  	text-align: left;
  	  	width: 95%;
  	  	text-indent:0px;
    	}
  		.menu-item > ul:before {
  			display: none;
  		}

      .menu-item li {
        padding-left: 5px;
      }

      .header-nav .main-menu li ul {
          display: none;
      }

      .header-nav .main-menu > li:first-child{
      	margin-left: 5px;
        padding-left: 4px;
      }

      .menu-item ul li:first-child {
          text-indent: 5px;
      }

      .menu-item  ul  {
        position: relative;
        display: flex;
      }

      .span-escondido{
        display:inline;
      }

      .bg-pesquisas {
        width:100%;
        p span {
        }
      }

      .box-info-usuarios{
        font-size: 1.8em;
      }

    .bg-toolhint{

    }

      .bg-toolhint i{
        float: none;
        margin-bottom: 5px;
      }

      .tabelas-forms tbody td:nth-child(1){
          width: 2%;
      }

      .tabelas-forms tbody td:nth-child(2){
          width: 6%;
      }
      .tabelas-forms tbody td:nth-child(3){
          width: 6%;
      }

      .tabelas-forms tbody td:nth-child(4){
          width: 16%;
      }

      .tabela-aluno tbody td:nth-child(3) {
          width: 18%;
      }

      .tabela-aluno tbody td:nth-child(4) {
          width: 10%;
      }

  .box-left-tabela {
    padding-left: 15px;
  }

  .form-inline {
    select {
      margin-bottom: 10px;
    }
  }
  
  /*.overscroll {
      overflow-x: scroll;
  }*/

  .btn-incluir-pesq {
    margin-top: 10px;
  }

  .bg-add{
    margin-top: 10px;
  }

  .barra-btn-pesq{
    button{
      margin-top: 5px;
    }
  }

  .form-search-action-btns {
    display: block;

    .form-search-action-btns__preview {
        margin-right: 0;
    }
  }

  .pesquisa-fixa {
    #input-pesquisar{
      width: 100%;
    }
  }
  .group-pesquisar{
    .btn-group {
      width:100%;
    }
  }

  .bg-form-medidas{
    width:auto;
  }

  .bg-avisos-filtro{
    width: 100% !important;
    height: auto;
  }

  .select-alt{
    width:100% !important;
  }
    .form-line-turma{
      margin-left: 0px !important;
      margin-right: 0px !important;
    }

    .select-alt-cont, .select-cont-esc{
      width: 100% !important;
    }

    .btn-fixed{
      display: none;
    }

    .form-view-desc{
      p{
        margin-bottom: 10px;
      }
    }

    .tabela-resposta, .tabela-escola {
      overflow-x: scroll !important;
    }

    .reset-password-error {
      padding: 10px 23px;
    }
}

@media only screen and (max-width: 600px){
  .multiselect .btn {
    min-width: 325px !important;
  }

  input.pesq-select {
    min-width: 360px !important;
  }

  .tabela-views{
    tbody td:first-child{
      vertical-align: top;
    }
    tbody td:nth-child(2){
      text-align: left;
      width:auto;
      vertical-align: top;
      .radio-inline + .radio-inline, .checkbox-inline + .checkbox-inline{
        margin-left: 0px;
      }

      .radio-inline{
        display: block;
      }
    }
  }

  .paginacao-modal{
    float: none;
  }

  .tabela-formularios {
    display: block;
  }

  .tabela-escola tbody td:nth-child(3) .checkbox-inline {
    display: block;
    }
  .check-etapas{
    text-align: left;
  }
  .check-first{
    margin-left: 10px;
  }
  .check-font-view{
    .checkbox{
      display: block;
      margin-left: 10px;
    }
  }

  .tabela-resposta, .tabela-escola {
    overflow-x: scroll;
  }

  .overscroll, table {
      overflow-y: scroll;
  }

}


/*Styles for screen 600px and lower*/


/*Styles for screen 515px and lower*/
@media only screen and (max-width : 480px) {
  html, body {
  overflow-x:hidden
}
.box-contents{
 overflow-y:visible !important;
}
  .wrap{
    overflow: hidden;
  }
  
  .radio.radio-inline {
    margin-left: 0;
    display: block;
  }

  #nav-ias{
    width:92%;
  }

  .topo {
    h3 {
      font-size:3.1em;
      text-align: right;
    }
  }

	.header-nav, .main-menu {
	    height: auto;
	}

  .header-nav .main-menu li ul {
      width: 100%;
  }


	.menu-item > ul {
		left: 5px;
	}

  .tabelas-forms tbody td:nth-child(4){
      width: 15%;
  }
  .tabelas-forms tbody td:nth-child(6){
      width: 10%;
  }

  .box-info-usuarios{
    font-size: 1.5em;
  }
  .tabelas-forms tbody{
    .select-formularios{
      width: 65px;
      font-size: 0.9em;
    }
  }

  .box-senha{
      .box-cad-senha{
        width: 85%;
      }
    }

    .coluna-acoordion {
      padding-right: 0px;
    }

    .box-descricao-accordion {
      margin-bottom: 5px;
    }

    .radio-block{
      display: block;
    }
    .radio-block:first-child{
      margin-left: 10px;
    }

    .tabelas-forms{
      overflow-x: scroll;
    }

    .bg-avisos{
      text-align: left;
      height: 100%;
    }
    .bg-menores span {
      float: none;
      text-align: left;
    }
    .bg-menores {
      i{
          font-size: 2.1em;
      }
    }

    .tabela-resposta, .tabela-escola {
      overflow-x: scroll;
    }

    .reset-password-error {
      padding: 10px 23px;
    }
  }

/*Smartphone*/
@media only screen and (max-width : 320px) {
  .tabela-resposta, .tabela-escola {
    overflow-x: scroll;
  }


  html, body {
  overflow-x:hidden
}
  .wrap {
    overflow-x: hidden;
  }

  .col-xx-12{
    width: 100%;
    position: relative;
    float: left;
    padding-right: 15px;
    padding-right: 15px;
  }
  .topo {
    h3 {
      font-size:3.8em;
      text-align: center;
      margin-top:5px;
    }

    .logo{
      text-align: center;
    }
  }

	#nav-ias li {
		display: block;
		width: 95%;
	}
	#nav-ias li a {
	}

  .coluna-acoordion {
    padding-right: 0px;
    width: 28%;
  }

  .coluna-descricao{
    width: 42%;
  }

  .form-pesquisar #input-pesq {
      min-width:100px !important;
    }

    .tabela-resposta {
      overflow-x: scroll;
    }

}

.resp-coleta{
  width: 100% !important;
}


.resp-coleta-pesq{
  width: 100% !important;
}
