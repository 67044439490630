@import url('https://fonts.googleapis.com/css?family=Dosis:300,500,600');
@import 'mixins/mixins.scss';
@import 'variaveis/variaveis.scss';
@import 'mixins/topo.scss';
@import 'mixins/reset.scss';
@import 'mixins/escala.scss';
@import 'mixins/modal.scss';
@import 'mixins/botoes-bgs.scss';
@import 'mixins/custom-rc.scss';
@import 'mixins/escola.scss';
@import 'mixins/usuario.scss';
@import 'mixins/turma.scss';
@import 'mixins/relatorio.scss';
@import 'mixins/loader.scss';

/***content***/

.alert-success {
    color: $branco;
    background-color: $verde-padrao;
    border-color: none;
}

.alert-success {
    background-image: -webkit-linear-gradient(top, $verde-padrao 0%, $verde-padrao 100%);
    background-image: -o-linear-gradient(top, $verde-padrao 0%, $verde-padrao 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from($verde-padrao), to($verde-padrao));
    background-image: linear-gradient(to bottom, $verde-padrao 0%, $verde-padrao 100%);
    background-repeat: repeat-x;
    border-color: $verde-padrao;
    color: $branco;
    border-color: none;
}

.wrap {
    padding-left: 0px;
    padding-right: 0px;
}

.box-contents {
    margin-bottom: 10px;
}

.content {}

.login {
    background: -moz-linear-gradient(top, #00aeef 0%, #39c5fa 37%, #39c5fa 37%, #39c5fa 68%, #00aeef 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #00aeef 0%, #39c5fa 37%, #39c5fa 37%, #39c5fa 68%, #00aeef 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #00aeef 0%, #39c5fa 37%, #39c5fa 37%, #39c5fa 68%, #00aeef 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00aeef', endColorstr='#00aeef', GradientType=0);
    /* IE6-9 */
    color: $branco;
    margin-top: 20px;
    a {
        text-decoration: none;
        color: $branco;
        font-size: 2.0em;
    }
    .box-content-login {
        h3 {
            font-weight: lighter;
        }
        width: 95%;
        position: relative;
        p {
            font-size: 2.3em;
            line-height: 1.5;
            font-weight: 100;
        }
    }
    .box-acesso {
        h3 {
            text-align: center;
        }
        label {
            color: $branco;
        }
    }
    .aside-login {
        float: right;
        a {
            font-size: 2.1em;
        }
        button {
            font-size: 2.4em;
        }
    }
}

.box-rec-senha {
    width: 50%;
    margin: 0 auto;
    padding: 10px;
    h3 {
        text-shadow: 1px 1px 2px rgba(20, 20, 20, 0.4);
    }
    p {
        font-size: 2.4em;
    }
}

.box-nova-senha {
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 50px;
}

.box-senha {
    @include background-opacity($cinza-box-info, 0.8);

    .box-cad-senha {
        background: none;
        width: 66.666666%;
        margin: 0 auto;
        a {
            color: $cinza-labels;
        }
        h3 {
            color: $azul-padrao;
        }
        p {
            color: $cinza-inputs;
            font-size: 2.4em;
        }
    }
}

.box-senha {
    padding: 10px;
}

.box-senha:last-child {
    border: none;
}

.login-modal {
    @include background-opacity($amarelo-claro,
    1.0);
    margin-top: 0;
}

.image-login {
    margin-bottom: 20px;
    text-align: center;
    max-width: 100%;
    img {
        margin: 0 auto;
    }
}


/***pesquisa***/

.barra-pesquisa {
    background: $cinza-claro;
    padding: 10px;
    @include border-radius(3px);
    margin-top: 10px;
}

.barra-btn-pesq {
    button {}
}

.btn-form-control {
    height: 30px;
}

.resultado {
    article {
        font-size: 2.4em;
        color: $amarelo-padrao;
    }
    p {
        font-size: 2.5em;
        text-transform: uppercase;
        font-weight: bolder;
    }
    .email-lowercase {
        text-transform: lowercase;
    }
    .codigo-resultado {
        color: $azul-padrao;
        cursor: pointer;
    }
    hr {
        border: 1px solid $cinza-box-linha;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.pesquisa-nao-enc {
    margin-top: 20px;
}

.group-pesquisar>#input-pesq,
.group-pesquisar>div>#input-pesq {
    min-width: 510px;
}

.form-pesquisar {
    button {
        padding-left: 10px;
        padding-right: 10px;
        height: 33px;
        i {
            font-size: 1.2em;
        }
    }
}

.pesquisa-fixa {
    #input-pesquisar {
        width: 300px;
    }
    button {
        height: 33px;
        padding-left: 10px;
        padding-right: 10px;
    }
}

.span-escondido {
    display: none;
}


/***pesquisa***/


/***migalha***/

.breadcrumb {
    background: none;
    font-size: 3.6em;
    padding: 0px;
    li {
        a,
        span {
            color: $azul-padrao;
            text-decoration: none;
            font-weight: lighter;
        }
        .active {
            color: $azul-escuro;
            font-weight: normal;
        }
    }
}

.breadcrumb>li+li:before {
    padding: 0 5px;
    color: $azul-claro;
    content: ">\00a0";
    font-size: 1.0em;
}

.breadcrumb>.active {
    color: $azul-escuro;
}


/***migalha***/


/***box-info-usuarios***/

.box-info-usuarios {
    background: $cinza-box-info;
    color: #afafaf;
    padding: 10px 10px;
    font-size: 2.4em;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 2px;
    b {
        color: #969696;
    }
    b.textup {
        text-transform: uppercase;
    }
}

.box-indo-modal {
    margin-top: 0px;
}


/***content***/


/**** Cadastro ****/

.bts-actions {
    background: #f4f3f3;
    padding: 10px;
    margin: 20px 0;
    .form-group {
        margin-bottom: 0;
    }
    .btn {
        margin-left: 5px;
        font-size: 2.2em;
    }
}


/*****tabelas***/

.table>thead>tr>th {
    border-bottom: 0px;
    vertical-align: middle;
}

.table>tbody>tr>td {
    border-bottom: 0px;
    vertical-align: middle;
}

.tabela tbody {
    font-size: 1.9em;
    color: #767676;
    background: $branco;
    border: none;
}

.tabela table {
    border: none;
}

.tabela thead {
    background: $azul-escuro;
    color: $branco;
    border-bottom: 2px solid $azul-escuro;
    font-weight: lighter;
    font-size: 1.9em;
}

.borderless td,
.borderless th {
    border: none;
}

.pesquisa-fixa {
    float: right;
}

.tabela-formularios {
    .checkbox {
        margin: 0px;
        padding-left: 10px;
    }
    font-size: 1.2em;
}

.tabela-formularios tbody td:nth-child(1) {
    font-style: normal;
}

.tabela-formularios tbody td:nth-child(2) {
    a {
        margin-left: 5px;
        text-decoration: none;
        i {
            color: $cinza-inputs;
            font-size: 0.8em;
        }
    }
}

.check-tabelas label {
    margin-right: 0px;
}

.check-tabelas {
    padding-right: 0px;
    i {
        color: $branco;
        font-size: 1.4em;
    }
}

.tabelas-forms tbody {
    .select-formularios {
        // width: 115px;
    }
}

.tabelas-forms tbody td:first-child {
    width: 7%;
}

.tabelas-forms tbody td:nth-child(2) {
    width: 5%;
    color: #454545;
}

.tabelas-forms tbody td:nth-child(3) {
    width: 10%;
}

.tabelas-forms tbody td:nth-child(4) {
    width: 45%;
}

.tabelas-forms tbody td:nth-child(5) {
    width: 10%;
}

.tabelas-forms tbody td:last-child {
    width: 10%;
}


/***importante remove 3 coluna tabela formulários"***/

.form-tabela tbody td:nth-child(3) {
    width: 0.4%;
}


/***/

.tabela-coletas tbody td:nth-child(4) {
    width: 18%;
}

.tabela-coletas tbody td:nth-child(5) {
    width: 35%;
}

.tabela-coletas tbody td:nth-child(6) {
    width: 9%;
}

.select-formularios {
    // color:$cinza-inputs;
}

.titulo-tabela-formulario article {
    font-size: 1.4em;
    margin-bottom: 5px;
}

.box-bts-tabelas {
    background: $cinza-claro;
    padding: 5px;
    button {
        margin-right: 10px;
        i {
            margin-right: 3px;
        }
    }
}

.box-bts-tabelas button:last-child {
    margin-right: 4px;
}

.tabelas-forms tbody {
    font-size: 2.0em;
    color: #9a9a9a;
    font-style: italic;
}

.form-ordem {
    .form-group {
        margin-bottom: 0px;
    }
}

.tabela-ordem {
    span {
        line-height: 3.0em;
    }
    i {
        padding: 0px;
    }
}

.tabela-relatorio {
    overflow-x: scroll;
    .tabela-relatorio-totais {
        font-weight: bold;
        background-color: #f0f0f0;
    }
}

.ordem-modal {
    display: -webkit-flex;
    display: flex;
    margin-bottom: 0px;
    i {
        font-size: 1.8em;
    }
    .ordem-icones {
        margin-bottom: 0px;
        display: block;
    }
    .icones-posicao {
        padding-left: 5px;
        padding-top: 8px;
        height: 8px;
    }
}

.btn-tabela-form {
    height: 33px;
}

.btn-vermelho-icone {
    font-size: 15px;
    padding: 0;
    margin: 0;
    vertical-align: middle;
    top: 0;
    display: table;
}

.btn-tabela-form {
    height: 33px;
}

.box-bts {
    background: $cinza-box-info;
}

.box-right {
    border: 1px solid red;
}

.box-left {
    border: 1px solid green;
}

div.box-bts {
    margin-bottom: 100px;
}

section.box-bts-tabelas {}

.borda {
    border: 1px solid #b8b8b8;
    border-radius: 2px;
}

.tabela-accordion tbody tr.tit-formulario-accordion {
    background: #f3f3f3;
    border-bottom: 2px solid #b8b8b8;
    font-size: 20px;
}

.panel {
    border: 1px solid #b8b8b8;
    border-radius: 2px;
}

.panel-heading {
    background: #f3f3f3;
    border-bottom: 2px solid #b8b8b8 !important;
    border-radius: 2px;
}

.tabela-radio {
    width: 20px;
}

.checkbox-accordion {
    float: left;
    margin-top: -2px;
}

.tit-table-accordion {
    background: #008fc4;
    color: #FFF;
    border-bottom: 2px solid #008fc4;
    font-weight: lighter;
    font-size: 1.8em;
    padding-left: 0;
    p {
        font-size: 1.2em;
        margin-bottom: 0;
    }
    .checkbox {
        margin-bottom: 0;
    }
}

.panel-heading a:before {
    content: '\e114';
    position: relative;
    font-family: 'Glyphicons Halflings';
    font-size: 18px;
    transition: all 0.5s;
    transform: scale(1);
    float: right;
}

.tabelas {
    margin-top: 20px;
}

.panel-body p {
    font-size: 14px;
}

.panel-body span {
    font-size: 14px;
    font-weight: lighter;
}

.coluna-acoordion {
    padding-right: 0px;
}

.panel-body .box-descricao-accordion {
    background: #c8e7f3;
    border-radius: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.glyphicon {
    margin-right: 3px;
}

.box-left-tabela {
    padding-left: 20px;
}

.tabela-views tbody>tr:nth-of-type(odd) {
    background: none;
}

.tabela-views {
    tbody tr {
        border-bottom: 2px solid $azul-escuro;
    }
    font-size: 1.1em;
    tbody td:first-child {
        vertical-align: middle;
    }
    tbody td:nth-child(2) {
        text-align: left;
        width: 42%;
        vertical-align: middle;
    }
    tbody td:nth-child(3) {
        width: 40%;
        padding-right: 0px;
        p {
            padding: 10px;
        }
        div p:nth-child(even) {
            background: $cinza-box-info;
        }
    }
}

.form-view {
    font-size: 2.0em;
    input,
    select,
    textarea {
        font-size: inherit;
    }
    label {}
    .titulo-label {
        color: $azul-padrao;
    }
    h4 {
        color: $azul-padrao;
        i {
            font-size: 1.2em;
        }
    }
}

.form-view-desc {
    p {
        /*width: 50%;*/
        @include background-opacity($azul-padrao,
        .2);
        padding: 10px;
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

.form-view-titulo {
    display: flex;
    i {
        font-size: x-large;
        margin-left: 5px;
        padding-top: 5px;
    }
}

.dinamico {
    hr {
        border: 1px dashed $cinza-inputs;
    }
    .quant-caractere {
        p {
            font-style: italic;
            font-size: 12px;
            text-align: right;
        }
        textarea {
            width: 100%;
        }
    }
}

.select-pesquisar {
    width: 510px;
}

.paginacao-modal {
    float: right;
}

.pesq-select {
    width: 510px !important;
}

.icone-posicao-unico {
    i {
        margin-top: 8px;
    }
}

.group-pesquisar button.multiselect {
    min-width: 510px;
    span {
        float: left;
    }
    .caret {
        display: none !important;
        float: right;
        margin-top: 8px;
    }
}


/*MultiSelect*/

.multiselect .btn {
    min-width: 510px !important;
    text-align: left;
    -moz-appearance: none !important;
    -ms-appearance: none !important;
    .caret {
        float: right;
        margin-top: 8px;
        display: none !important;
    }
}

.multiselect .dropdown-menu>li>a:hover {
    color: #008fc4 !important;
}

.multiselect .dropdown-menu>li>a.item-selected:hover {
    color: #fff !important;
}

.multiselect-container {
    top: 100%;
    width: 100%;
    margin-bottom: 50px;
    li a {
        background-color: #fff;
        font-size: 7px;
    }
}

.multiselect-container.dropdown-menu>.active>a,
.multiselect-container.dropdown-menu>a {
    background-color: #fff !important;
    background: #fff !important;
    background-image: #fff !important;
}

.multiselect-container li a label.radio input[type=radio],
.multiselect-container li a label.checkbox input[type=checkbox] {
    position: absolute;
    border: 1px solid #efefef;
    opacity: 1;
    top: 4px;
}

.form-inline .multiselect-container label.radio {
    padding-left: 30px;
}

.has-error .multiselect .btn {
    border-color: #a94442 !important;
}

.box-right-tabela .bg-avisos-errors {
    right: 300px;
    margin-top: -2px;
}


/*Tela de Erro*/

.container-error {
    font-size: 25px;
    text-align: center;
    h1 {
        font-size: 150px !important;
        color: #479d40;
    }
    .erro-msg {
        font-size: 16px;
        width: 500px;
        margin: 0 auto;
        margin-top: 40px;
        margin-bottom: 40px;
        text-align: left;
        padding: 20px;
        background: #efefef;
        border-radius: 5px;
    }
    .sub a {
        color: #00aeef;
    }
}


/*Load*/

#loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99998;
}

#loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #00a850;
    -webkit-animation: spin 2s linear infinite;
    /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 2s linear infinite;
    /* Chrome, Firefox 16+, IE 10+, Opera */
    z-index: 99999;
}

#loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #ffc70c;
    -webkit-animation: spin 3s linear infinite;
    /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 3s linear infinite;
    /* Chrome, Firefox 16+, IE 10+, Opera */
}

#loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #002e5e;
    -webkit-animation: spin 1.5s linear infinite;
    /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 1.5s linear infinite;
    /* Chrome, Firefox 16+, IE 10+, Opera */
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(0deg);
        /* IE 9 */
        transform: rotate(0deg);
        /* Firefox 16+, IE 10+, Opera */
    }
    100% {
        -webkit-transform: rotate(360deg);
        /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(360deg);
        /* IE 9 */
        transform: rotate(360deg);
        /* Firefox 16+, IE 10+, Opera */
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(0deg);
        /* IE 9 */
        transform: rotate(0deg);
        /* Firefox 16+, IE 10+, Opera */
    }
    100% {
        -webkit-transform: rotate(360deg);
        /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(360deg);
        /* IE 9 */
        transform: rotate(360deg);
        /* Firefox 16+, IE 10+, Opera */
    }
}

#loader-wrapper .loader-section {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99998;
    -webkit-transform: translateX(0);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: translateX(0);
    /* IE 9 */
    transform: translateX(0);
    /* Firefox 16+, IE 10+, Opera */
}

#loader-wrapper .loader-section.section-left {
    left: 0;
}

#loader-wrapper .loader-section.section-right {
    right: 0;
}


/* Loaded */

.loaded #loader-wrapper .loader-section.section-left {
    -webkit-transform: translateX(-100%);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: translateX(-100%);
    /* IE 9 */
    transform: translateX(-100%);
    /* Firefox 16+, IE 10+, Opera */
    -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
    transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
}

.loaded #loader-wrapper .loader-section.section-right {
    -webkit-transform: translateX(100%);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: translateX(100%);
    /* IE 9 */
    transform: translateX(100%);
    /* Firefox 16+, IE 10+, Opera */
    -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
    transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
}

.loaded #loader {
    opacity: 0;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.loaded #loader-wrapper {
    visibility: hidden;
    -webkit-transform: translateY(-100%);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: translateY(-100%);
    /* IE 9 */
    transform: translateY(-100%);
    /* Firefox 16+, IE 10+, Opera */
    -webkit-transition: all 0.3s 1s ease-out;
    transition: all 0.3s 1s ease-out;
}


/* JavaScript Turned Off */

.no-js #loader-wrapper {
    display: none;
}

.no-js h1 {
    color: #222222;
}


/*Coleta css */

.coleta-data {
    margin-bottom: 5px;
}

.status-resposta-ok {
    @include background-opacity($verde-claro,
    .3);
    height: 0%;
}

.status-resposta-pen {
    @include background-opacity($amarelo-claro,
    .3);
    height: 0%;
}

.status-resposta-inv {
    @include background-opacity($vermelho-claro,
    .3);
    height: 0%;
}

.status-data {
    @include background-opacity($cinza-claro,
    .2);
    font-size: 0.9em;
}

.tabela-resposta {
    th {
        a {
            text-decoration: none;
            color: $branco;
            &:hover {
                color: $amarelo-padrao;
            }
        }
    }
    tbody {
        button {
            font-size: 0.9em;
            width: 70px;
        }
    }
}

.filtro-situacao {
    margin-bottom: 10px;
    span:first-child {
        font-size: 1.6em;
    }
}

.coleta-solicitacao {
    @include background-opacity ($amarelo-claro,
    .5);
    font-size: 2.0em;
    padding: 10px;
}

.resp-coleta-pesq {
    width: 535px !important;
}

.resp-coleta {
    width: 650px !important;
}

.tabela-alvo {
    opacity: 0.7;
}

.linha-pintada {
    background: rgba(113, 188, 107, 0.5) !important;
}

.tabela-solicitacao {
    margin-bottom: 0px;
    padding: 5px 5px;
    span {
        margin-right: 10px;
        color: $branco;
        padding-right: 5px;
        font-size: 0.9em;
        font-weight: lighter;
        text-align: justify;
        b {
            font-weight: bolder;
            color: $branco; //$amarelo-padrao;
        }
    }
    span:first-child {
        font-weight: bolder;
        text-transform: uppercase;
    }
}

.solicitacao-fechada {
    span {
        b {
            color: $branco; //$azul-escuro;
        }
    }
}

.modal-coleta {
    h3 {
        color: $azul-padrao;
    }
}

.panel-solicitacao {
    padding: 0px;
    thead {
        text-decoration: none;
    }
}

.tabela-solicitacao-int {
    tbody {
        td {
            font: 2.0em;
        }
    }
}

// .table{
//   display: table;
//   width:100%
// }
// .row{
//   display: table-row;
// }
.cell {
    display: table-cell;
    width: 20%
}

.ordem-resposta {
    i {
        font-size: 1.0em;
    }
}

.group-pesquisar button.multiselect {
    min-width: 510px;
    span {
        float: left;
    }
    .caret {
        float: right;
        display: none !important;
        margin-top: 8px;
    }
}

.form-m-view {
    .form-obrigatorio {
        font-size: 12px;
    }
}

.check-font-view {
    .checkbox {
        font-size: 0.75em;
    }
}


/*resposta coleta */

.resp-num {
    margin: 5px 0 !important;
    margin-top: 5px;
}

.resp-num>.active>a {
    background: $azul-claro !important;
}

.resp-num>.active {
    border: none !important;
}

.resp-num>li>a,
.resp-num>li>span {
    margin-left: 10px !important;
    border: none !important;
    background: #d0d0d0;
}

.resp-num>li>a:hover,
.resp-num>li>span:hover,
.resp-num>li>a:focus,
.resp-num>li>span:focus {
    z-index: 2;
    color: $branco;
    background-color: $azul-claro;
    border-bottom: 1px solid $azul-padrao;
    @include transition(.4);
    @include border-radius(3px);
}

.resp-header-info {
    padding-left: 5px;
    margin-bottom: 5px;
    border-left: 4px solid $azul-claro;
    h4 {
        color: $azul-claro;
    }
}

.resp-header-sucess {
    padding-left: 5px;
    border-left: 4px solid $verde-padrao;
    h3 {
        color: $verde-padrao;
    }
}

.resp-header-error {
    padding-left: 5px;
    border-left: 4px solid $amarelo-padrao;
    h3 {
        color: $vermelho-claro;
    }
}

.resp-per {
    @include background-opacity($azul-padrao,
    .3);
    padding: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-left: 4px solid #00aeef;
}

.dados-coleta {
    padding-left: 5px;
    border-left: 4px solid $azul-escuro;
    h3 {
        color: $azul-escuro;
        &:last-child {
            color: $azul-padrao;
        }
    }
    h4 {
        color: $azul-padrao;
    }
}

.item-form-desc {
    display: inline-flex;
    vertical-align: middle;
    line-height: 50px;
    i {
        font-size: 1.7em;
        margin-left: 5px;
    }
}

.bg-alertas-form {
    i {
        font-size: 1.5em;
    }
}

.bg-error-resposta {
    display: inline-flex;
    margin-bottom: 5px;
    padding-top: 10px;
    i {
        font-size: 3.0em;
        margin-left: 5px;
    }
    p {
        margin-bottom: 5px;
        margin-left: 5px;
    }
    p::before {
        content: "\f111";
        color: $branco;
        font-family: FontAwesome;
        font-style: normal;
        margin-right: 5px;
    }
}

.titulo-btns {
    display: inline-flex;
    span {
        margin-left: 5px;
        margin-top: 15px;
    }
}

.bg-resp-coleta {
    margin-top: 10px;
}

.bg-add {
    margin-top: 10px;
}

.titulo-granu-resp {
    color: $azul-escuro;
    font-weight: 800;
}

.btn-hover-sav {
    display: none !important;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    transition: 0.4s;
}

.btn-hover-can {
    display: none !important;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    transition: 0.4s;
}


/*Turmas*/

.form-turma-escola {
    margin-left: 0px;
    margin-right: 0px;
}

.form-line-turma {
    padding-top: 7px;
    padding-bottom: 7px;
    margin-top: 5px;
}


/*contratos*/

.select-alt-cont {
    width: 46% !important;
}

.select-cont-esc {
    width: 42.5% !important;
}

.form-line-cont {
    background: none;
}


/* margins */

.margin-bottom-xs {
    margin-bottom: 10px;
}

.box-bts-tabelas {
    .btn {
        // Use this for transitions
        &.ng-enter,
        &.ng-leave,
        &.ng-animate {
            -o-transition: none !important;
            -moz-transition: none !important;
            -ms-transition: none !important;
            -webkit-transition: none !important;
            transition: none !important;
        }
        // Use this for keyframe animations
        &.ng-animate {
            -o-animation: none 0s;
            -moz-animation: none 0s;
            -ms-animation: none 0s;
            -webkit-animation: none 0s;
            animation: none 0s;
        }
    }
    * {
        /*CSS transitions*/
        -o-transition-property: none !important;
        -moz-transition-property: none !important;
        -ms-transition-property: none !important;
        -webkit-transition-property: none !important;
        transition-property: none !important;
        /*CSS transforms*/
        -o-transform: none !important;
        -moz-transform: none !important;
        -ms-transform: none !important;
        -webkit-transform: none !important;
        transform: none !important;
        /*CSS animations*/
        -webkit-animation: none !important;
        -moz-animation: none !important;
        -o-animation: none !important;
        -ms-animation: none !important;
        animation: none !important;
    }
}

.radio-group-label-inline {
    margin-top: 10px;
    margin-bottom: 10px;
}
.label-inline {
    margin-top: 10px;
    margin-bottom: 10px;
}
.label-left-alignment {
    padding-left: 0px;
}
.label-right-aligment {
    padding-right: 0px;
}

.form-group {
    .radio-group-label-inline {
        margin-top: 10px;
        margin-bottom: 0px;
    }
    .label-inline {
        margin-top: 10px;
        margin-bottom: 0px;
    }
}

.tabela-aluno tbody td:nth-child(3) {
  width: 35%;
}

.tabela-aluno tbody td:nth-child(4) {
  width: 15%;
}

.coleta-import-msg {
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 15px;

    .coleta-import-msg__error {
        cursor: pointer;
        display: inline-block;
    }

    .coleta-import-msg__text {
        font-size: 18px;
        padding-left: 5px;
    }
}

.coleta-import-file-label {
    color: #999;
}

.coleta-import-file-error {
    display: block;
}

.modal-coleta-import {
    max-height: 400px;
    overflow: auto;
}

.modal-coleta-import-errors {
    .alert {
        margin-bottom: 5px;
    }

    .modal-coleta-import-errors__form-name {
        font-size: 18px;
        display: block;
        margin-bottom: 8px;
    }

    .modal-coleta-import-errors__msgs span {
        display: block
    }
}

.form-search-action-btns {
    display: flex;
    justify-content: flex-end;

    .form-search-action-btns__preview {
        margin-right: 1px;
    }
}

.bootstrap .tags .tag-item {
  background: #00aeef;
  border: 1px solid #357ebd;
  border-radius: 4px;
  color: #fff;
}

.bootstrap .tags .tag-item.selected {
  background: #ec1c24;
  border: 1px solid #b81319;
  border-radius: 4px;
  color: #fff;
}

.forgotten-password {
    .modal-footer {
        padding: 5px 10px;

        .box-bts-tabelas {
            padding: none;
        }

        .bg-avisos {
            position: inherit;
            margin-right: 0;
        }
    }
}

.reset-password-loader {
    padding: 40px 0 50px;
}

.reset-password-error {
    padding: 10px 0;
}

.opcao-rubrica {
    max-height: 80px;
    overflow:hidden;
    text-overflow:ellipsis;
    width: 75px;
    height: 75px; 
    background: rgb(233, 227, 227);
    -moz-border-radius: 50px; 
    -webkit-border-radius: 50px; 
    border-radius: 50px;    
    font-size: 36px;
    text-align: center;
    vertical-align: middle;
    margin-left: 5px;
}

.erro-rubrica {
    color:#ec1c24;
    font-weight: bolder;
}

.menor .btn {
    min-width:250px !important;
    width:100% !important;

}

@import 'mixins/responsivo.scss';

tr.last-td-bold td:last-child {
    font-size: 1.2em;
    font-weight: 900;
    color: black;
}

tr.even-right td:nth-child(even) {
    text-align: right;
}

tr.odd-center td:nth-child(odd) {
    text-align: center;
}

tr.first-td-center td:first-child {
    text-align: center;
}

.td-font-bold {
    font-size: 1.2em;
    font-weight: 900;
    color: black;
    padding-top: 5px !important; 
    padding-bottom: 5px !important;
}

tr.td-gt-2-right td:nth-child(n+2) {
    text-align: right;
}

tr.td-gt-2-center td:nth-child(n+2) {
    text-align: center;
}

footer.login-footer {
    color: gray;
    font-size: 2.3em;
    line-height: 1.5;
    font-weight: 100;
    text-align: center;
    margin-bottom: 10px;
    font-family: 'Dosis', sans-serif;
}

a.login-link {
    font-size: 1em;
    font-weight: bold;
    text-decoration: underline;  
}