$font-default: 'Dosis', sans-serif; 

$branco:#FFF;
$preto:#000;

$azul-padrao:#00aeef;
$azul-claro:#39c5fa;
$azul-escuro:#008fc4;
$azul-mais-claro:#edf8ff;
$azul-inputs-pophovers:#30b7e9;
$azul-header-tables:#0072bb;

$amarelo-padrao:#fbae17;
$amarelo-claro:#edce19;
$amarelo-escuro:#d49313;
$amarelo-btn-claro-borda:#d7c142;
$amarelo-toolhint:#ffec91;

$verde-padrao:#50b748;
$verde-claro:#71bc6b;
$verde-escuro:#479d40;
$verde-btn-claro-borda:#47a240;
$verde-toolhint:#9be296;

$vermelho-padrao:#ec1c24;
$vermelho-claro:#f8787d;
$vermelho-escuro:#b81319;
$vermelho-btn-claro-borda:#d34b57;
$amarelo-toolhint:#ffec91;

$preto-texto-padrao:#666666;
$cinza-texto-tabela:#b0b0b0;
$cinza-claro:#f0f0f0;
$cinza-textos-placeholder:#f0f0f0;
$cinza-inputs:#b8b8b8;
$cinza-labels:#666666;
$cinza-box-linha:#dedede;
$cinza-box-info:#e8e8de;
$cinza-pagination:#c9ced1;
$cinza-borda-pagination:#bec2c4;
$cinza-escuro:#a2a2a2;
$cinza-pesquisa:#d7d7d7;
$cinza-links:#dddddd;
$cinza-nav-bar:#ebebeb;

$cor-pop-hover-1:#ffec91;
$cor-pop-hover-2:#cce9fd;

$dusty-gray: #999;