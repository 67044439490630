.modal-header, .modal-footer { 
  background: $cinza-claro;
  .modal-title {
    color: $azul-padrao;
    font-size:3.4em;
  }
  .close-modal {
    color: $vermelho-claro;
    border:1px solid $vermelho-claro;
    padding: 15px;
    @include border-radius(100%);
    width: 10px;
    height: 10px;
    opacity: 1.0;
    font-size: 2.8em;
    font-weight: lighter;
    float: right;
    opacity: .4;
    position: relative;
    @include transition (.4);

    i {
      position: absolute;
      right: 3px;
      top: 7px;
    }
    &:hover {
      opacity: 1.0;
      background:rgba(255,255,255,0.0);
    }

  }
}

.modal-footer .btn + .btn {
  margin-left: 0px;
}
