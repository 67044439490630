.cod-id, .situacao-forms {
  background: $azul-mais-claro;
}

.grupo-validacao {

}

.bg-form-medidas{
    background: $azul-mais-claro;
    border: 1px solid $cinza-inputs;
    @include border-radius(3px);
}
.form-Line-medidas {
  padding-top: 7px;
  padding-bottom: 7px;
  margin-top: 5px;
}

.validacao-medida {
  text-align: center;
  input {
    font-size: 2.0em;
  }
}

.label-ajuste{
  padding-top: 5px;
}

.ajuste-input{
  padding-left:  0px;
  padding-right: 0px;
}

.medida-linha{
  margin-right: 0px;
}
